<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Some data collected from this experiment is tabulated below. Review the data and then use it
        to perform each of the calculations at the bottom of the page. It can be assumed that the
        solution inside the can is water and has a heat capacity of
        <stemble-latex content="$\text{4.184 J}\,\text{g}^{-1 \, \circ}\text{C}^{-1}$" />
        and that the can itself has a heat capacity of
        <stemble-latex content="$\text{0.900 J}\,\text{g}^{-1 \, \circ}\text{C}^{-1}.$" />
      </p>

      <v-simple-table style="max-width: 413px">
        <thead>
          <tr>
            <td>
              <stemble-latex content="$\textbf{Variable}$" />
            </td>
            <td style="text-align: right">
              <stemble-latex content="$\textbf{Value}$" />
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <stemble-latex content="$\text{Salt}$" />
            </td>
            <td style="text-align: right">
              <stemble-latex :content="salt" />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\Delta\text{H}_\text{soln}$" />
              <stemble-latex content="$\text{ (J/mol)}$" />
            </td>
            <td style="text-align: right">
              <stemble-latex :content="deltaHsolnLatex" />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Mass of}$" />
              <stemble-latex content="$\text{ can}$" />
              <stemble-latex content="$\text{ (g)}$" />
            </td>
            <td style="text-align: right">
              <number-value :value="massCan" />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Mass of soln}$" />
              <stemble-latex content="$\text{ inside}$" />
              <stemble-latex content="$\text{ can (g)}$" />
            </td>
            <td style="text-align: right">
              <number-value :value="massCanWater" />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Mass of }$" />
              <stemble-latex :content="salt" />
              <stemble-latex content="$\text{ (g)}$" />
            </td>
            <td style="text-align: right">
              <number-value :value="massSalt" />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{Mass of water}$" />
              <stemble-latex content="$\text{ added to}$" />
              <stemble-latex content="$\text{ jacket (g)}$" />
            </td>
            <td style="text-align: right">
              <number-value :value="massJacketWater" />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{T}_\text{i}$" />
              <stemble-latex content="$\,(^\circ\text{C})$" />
            </td>
            <td style="text-align: right">
              <number-value :value="Ti" />
            </td>
          </tr>

          <tr>
            <td>
              <stemble-latex content="$\text{T}_\text{f}$" />
              <stemble-latex content="$\,(^\circ\text{C})$" />
            </td>
            <td style="text-align: right">
              <latex-number :number="Tf.toFixed(2)" />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p class="mb-2 mt-4">
        a) How much heat would you expect to be <b>{{ heatDirection }}</b> the can during this
        process?
      </p>

      <calculation-input
        v-model="inputs.qCan"
        prepend-text="$\left|\text{q}_\text{can}\right|:$"
        append-text="$\text{J}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) How much heat would you expect to be <b>{{ heatDirection }}</b> the solution inside the
        can?
      </p>

      <calculation-input
        v-model="inputs.qSoln"
        prepend-text="$\left|\text{q}_\text{soln}\right|:$"
        append-text="$\text{J}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c) How much heat would you expect to be <b>{{ heatDirection }}</b> the water in the
        "cooling" jacket?
      </p>

      <calculation-input
        v-model="inputs.qJacket"
        prepend-text="$\left|\text{q}_\text{jacket}\right|:$"
        append-text="$\text{J}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">d) How many moles of salt were used in the reaction?</p>

      <calculation-input
        v-model="inputs.nSalt"
        prepend-text="$\text{n}_\text{salt}:$"
        append-text="$\text{mol}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        e) Considering the answers above, calculate the experimental value of
        <stemble-latex content="$\Delta\text{H}_\text{soln}$" />
        for this reaction.
      </p>

      <calculation-input
        v-model="inputs.deltaHRxn"
        prepend-text="$\Delta\text{H}_\text{soln}:$"
        append-text="$\text{J/mol}$"
        class="mb-5"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        f) Considering the literature value of
        <stemble-latex content="$\Delta\text{H}_\text{soln}$" />
        provided in the data table, what is the percent error for this value based on the
        experimental data?
      </p>

      <calculation-input
        v-model="inputs.pctError"
        prepend-text="$\%\,\text{Error:}$"
        append-text="$\%$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';
import ChemicalSymbolInput from '@/tasks/components/inputs/ChemicalSymbolInput.vue';
import NumberValue from '@/tasks/components/NumberValue.vue';
import LatexNumber from '@/tasks/components/displayers/LatexNumber.vue';

export default {
  name: 'ChemMcMPL4_Q10b',
  components: {
    LatexNumber,
    NumberValue,
    ChemicalSymbolInput,
    ChemicalNotationInput,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        qCan: null,
        qSoln: null,
        qJacket: null,
        nSalt: null,
        deltaHRxn: null,
        pctError: null,
      },
      massRules: [
        (v) => !!v || 'Mass is required',
        (v) =>
          /^[-+]?\d+(\.\d*)?([eE][-+]?\d+(\.\d*)?)?$/.test(v) ||
          'Only numerical characters allowed',
        (v) =>
          (v && v.toString().split('.').length < 2) ||
          (v && v.toString().split('.')[1].length === 3) ||
          "Number of decimal places doesn't match balance output",
      ],
      sigFigRules: [
        (v) => !!v || 'This value is required',
        (v) =>
          /^[-+]?\d+(\.\d*)?([eE][-+]?\d+(\.\d*)?)?$/.test(v) ||
          'Only numerical characters allowed',
        (v) =>
          (v && v.toString().split('.').length < 2) ||
          (v && v.toString().split('.')[1].length === 3) ||
          'Double check your significant figures',
      ],
    };
  },
  computed: {
    massCan() {
      return this.taskState.getValueBySymbol('massCan').content;
    },
    massCanWater() {
      return this.taskState.getValueBySymbol('massCanWater').content;
    },
    massSalt() {
      return this.taskState.getValueBySymbol('massSalt').content;
    },
    massJacketWater() {
      return this.taskState.getValueBySymbol('massJacketWater').content;
    },
    Ti() {
      return this.taskState.getValueBySymbol('Ti').content;
    },
    tempScale() {
      return this.taskState.getValueBySymbol('tempScale').content;
    },
    versionNumber() {
      return this.taskState.getValueBySymbol('versionNumber').content ?? 4;
    },
    salt() {
      if (this.versionNumber.value === 1) {
        return '$\\ce{KBr}$';
      } else if (this.versionNumber.value === 2) {
        return '$\\ce{NH4Br}$';
      } else if (this.versionNumber.value === 3) {
        return '$\\ce{KNO3}$';
      } else {
        return '$\\ce{LiCl}$';
      }
    },
    molarMassSalt() {
      if (this.versionNumber.value === 1) {
        return 119.002;
      } else if (this.versionNumber.value === 2) {
        return 97.94;
      } else if (this.versionNumber.value === 3) {
        return 101.1032;
      } else {
        return 42.394;
      }
    },
    heatDirection() {
      if (this.versionNumber.value === 4) {
        return 'absorbed by';
      } else {
        return 'absorbed from';
      }
    },
    deltaHsoln() {
      if (this.versionNumber.value === 1) {
        return 19900;
      } else if (this.versionNumber.value === 2) {
        return 16800;
      } else if (this.versionNumber.value === 3) {
        return 34900;
      } else {
        return -37000;
      }
    },
    deltaHsolnLatex() {
      return '$' + this.deltaHsoln.toString() + '$';
    },
    deltaH() {
      return (this.deltaHsoln * this.massSalt.toFloat()) / this.molarMassSalt;
    },
    Tf() {
      if (this.versionNumber.value === 4) {
        return (
          (-this.deltaH /
            ((this.massCanWater.toFloat() + this.massJacketWater.toFloat()) * 4.184 +
              this.massCan.toFloat() * 0.9)) *
            this.tempScale.toFloat() +
          this.Ti.toFloat()
        );
      } else {
        return (
          (-this.deltaH /
            ((this.massCanWater.toFloat() + this.massJacketWater.toFloat()) * 4.184 +
              this.massCan.toFloat() * 0.9)) *
            this.tempScale.toFloat() +
          this.Ti.toFloat()
        );
      }
    },
  },
};
</script>
<style scoped></style>
